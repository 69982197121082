import axios from "axios";

const getAllEmployee = async ({departmentId, skip, companyId, find_by, find_value, sort_by, sort_order, limit }) => {
  try {
    const params = {
      limit: 10,
      skip: skip,
      department_id: departmentId,
      company_id: companyId,
      find_by,
      find_value,
      sort_by,
      sort_order,
    };

    const response = await axios.get(`https://domo.topiaapp.com/api/employee`, { params });

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export default getAllEmployee;
